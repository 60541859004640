import TYPES from '@/types';

// Application
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetGlobalTrackingQuery from '@/modules/flagship/investor-goal/global-tracking/application/queries/get-global-tracking-query';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetIsAvailableToHireStrategyModerateQuery
  from '@/modules/flagship/strategy-moderate/hire/application/queries/get-is-available-to-hire-strategy-moderate-query';

// Domain
import { GlobalTrackingEntity } from '@/modules/flagship/investor-goal/global-tracking/domain/entities/global-tracking-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import { InvestorProfileStateManager }
  from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipDefineGoalsCardViewModel {
  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_GLOBAL_TRACKING_QUERY)
  readonly get_global_tracking_query!: GetGlobalTrackingQuery;

  @Inject(TYPES.GET_IS_AVAILABLE_TO_HIRE_STRATEGY_MODERATE_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_is_available_to_hire_strategy_moderate!: GetIsAvailableToHireStrategyModerateQuery;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  private readonly investor_profile_state_manager!: InvestorProfileStateManager;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.my-investment-dashboard.flagship-define-goals.flagship_define_goals_card';

  is_loading = false;

  is_completed = false;

  goals_completed = false;

  show_flagship_goals_modal = false;

  show_flagship_goals_wizard_modal = false;

  show_all_flagship_goals_modal = false;

  show_flagship_goals_dialog = false;

  show_contract_moderate_dialog = false;

  show_choose_goal_link_dialog = false;

  show_hiring_flow_dialog = false;

  show_exit_poll_dialog = false;

  investor_goal_name = '';

  investor_goal_id = '';

  active_goals_count = 0;

  has_active_goals = false;

  progress_message = '';

  search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  global_tracking: GlobalTrackingEntity = {
    global_tracking_percent: 0,
    current_global_balance: 0,
    expected_global_balance: 0,
  };

  associated_product_ids = {
    pocket: '',
    wealth: '',
  };

  get has_linked_goals(): boolean {
    return !!this.active_goals_count;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showAllFlagshipGoalsModal = async () => {
    await this.loadGoalDefinitionStep();
    if (this.is_completed) {
      this.show_all_flagship_goals_modal = true;
    } else {
      this.show_flagship_goals_dialog = true;
    }
  }

  showDefineGoalsWizard = () => {
    this.show_flagship_goals_wizard_modal = true;
    this.show_flagship_goals_modal = false;
  }

  closeDefineGoalsModal = () => {
    this.show_flagship_goals_wizard_modal = true;
    this.show_flagship_goals_modal = false;
  }

  get background_image() {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const bgImage = require('@/assets/images/investment-dashboard/goals_card.svg');
    return {
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'right',
      backgroundPositionY: 'bottom',
      backgroundSize: 'contain',
    };
  }

  onCardCompletedClick = () => {
    this.router.navigate('/goals-dashboard');
  };

  loadGoalDefinitionStep = async () => {
    try {
      const { main_item } = await this.get_goal_definition_step_query.execute(false);

      this.show_flagship_goals_wizard_modal = main_item !== 'informative' && main_item !== undefined;
      this.show_flagship_goals_modal = main_item === 'informative' || main_item === undefined;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };

  initializeGoalsIncomplete = async () => {
    await this.loadGoalDefinitionStep();
  }

  initializeGoalsComplete = async () => {
    await this.getInvestmentProducts();
    await this.loadAllGoals();
  }

  loadInvestorProfile = async () => {
    try {
      const { investor_profile } = this.investor_profile_state_manager.state;
      if (investor_profile) {
        this.goals_completed = investor_profile.goals_completed;
        this.is_completed = investor_profile.is_completed;
        this.active_goals_count = investor_profile.active_goals_count;

        if (!this.goals_completed) {
          await this.initializeGoalsIncomplete();
        }

        if (this.goals_completed) {
          await this.initializeGoalsComplete();
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  loadGlobalTracking = async () => {
    try {
      this.global_tracking = await this.get_global_tracking_query.execute();
      this.global_tracking.global_tracking_percent = (
        this.global_tracking.global_tracking_percent > 100
      )
        ? 100
        : Number(this.global_tracking.global_tracking_percent.toFixed(0));
      this.setGoalProgressStatus(this.global_tracking.global_tracking_percent);
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_global_tracking_query'));
    }
  }

  setGoalProgressStatus = (percent: number) => {
    switch (true) {
      case percent >= 80:
        this.progress_message = this.translate('going_according_to_plan');
        break;
      case (percent >= 65 && percent < 80):
        this.progress_message = this.translate('deviated_slightly_from_your_plan');
        break;
      case (percent >= 50 && percent < 65):
        this.progress_message = this.translate('deviated_from_your_plan');
        break;
      default:
        this.progress_message = this.translate('less_than_half_of_planned');
        break;
    }
  }

  getInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      investment_products.forEach((product) => {
        if (product.name === 'sowos_pocket') {
          this.associated_product_ids.pocket = product.id;
        }
        if (product.name === 'sowos_wealth') {
          this.associated_product_ids.wealth = product.id;
        }
      });
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  };

  loadAllGoals = async () => {
    try {
      this.search_by_customer_dto.associated_product_id = this.associated_product_ids.pocket;
      const pocket_active_goals = await this.get_search_by_customer_query
        .execute(this.search_by_customer_dto);
      this.search_by_customer_dto.associated_product_id = this.associated_product_ids.wealth;
      const wealth_active_goals = await this.get_search_by_customer_query
        .execute(this.search_by_customer_dto);

      if (wealth_active_goals.length) {
        this.validateIfModerateHireIsAvailable();
      }

      if (wealth_active_goals.length || pocket_active_goals.length) {
        this.has_active_goals = true;
        await this.loadGlobalTracking();
      }
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  showExitPollDialog = () => {
    this.show_exit_poll_dialog = true;
  }

  showChooseGoalLinkDialog = () => {
    this.show_choose_goal_link_dialog = true;
  }

  showHiringFlowDialog = (investor_goal_id = '', investor_goal_name = '') => {
    this.show_choose_goal_link_dialog = false;
    this.investor_goal_id = investor_goal_id;
    this.investor_goal_name = investor_goal_name;
    this.show_hiring_flow_dialog = true;
  }

  backToProductInformation = () => {
    this.show_contract_moderate_dialog = false;
    this.show_exit_poll_dialog = true;
  }

  validateIfModerateHireIsAvailable = async () => {
    try {
      const { moderate_is_available_to_contract } = await this
        .get_is_available_to_hire_strategy_moderate.execute();
      this.show_contract_moderate_dialog = moderate_is_available_to_contract;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.hire_strategy_moderate_is_available'));
    }
  }
}
