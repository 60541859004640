







































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import FlagshipDefineGoalsCardViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/flagship-define-goals-card-view-model';

@Component({
  name: 'FlagshipDefineGoalsCard',
  components: {
    LoadingDataCard,
    FlagshipGoalsModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsModal.vue'),
    FlagshipGoalsWizardModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardModal.vue'),
    FlagshipDefineGoalsDialog: () => import('@/vue-app/components/my-investment-dashboard-v2/FlagshipDefineGoalsDialog.vue'),
    StrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthDialog.vue'),
    StrategyModerateChooseGoalLinkDialog: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateChooseGoalLinkDialog.vue'),
    StrategyModerateHiringFlowDialog: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateHiringFlowDialog.vue'),
    StrategyModerateWealthExitPoll: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthExitPoll.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipDefineGoalsCard extends Vue {
  flagship_define_goals_card_view_model = Vue.observable(new FlagshipDefineGoalsCardViewModel());

  @Watch('flagship_define_goals_card_view_model.investor_profile_state_manager.state.investor_profile.id')
  onInvestorProfileIdChange(id: string) {
    if (id) {
      this.flagship_define_goals_card_view_model.loadInvestorProfile();
    }
  }

  created() {
    this.flagship_define_goals_card_view_model.loadInvestorProfile();
  }
}
